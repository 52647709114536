import TrialBanner from "components/common/footer-banners/TrialBanner";
import GlobalLayout from "components/page/GlobalLayout";
import DownloadHeader from "components/thank-you/DownloadHeader";
import ExperienceCards from "components/thank-you/ExperienceCards";
import { StaticQuery, graphql } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

export default function VendorScoreCardThankYouPage() {
  const renderContent = data => {
    return (
      <GlobalLayout>
        <Helmet>
          <title>Your Scorecard Is Ready!</title>
          <meta
            name="description"
            content="Smarter, safer spaces are just a a click away. Download the Physical Security Provider Scorecard below and start assessing your security operations."
          />
        </Helmet>
        <DownloadHeader
          title="Your Scorecard is Ready!"
          blurb="Smarter, safer spaces are just a a click away. Download the Physical Security Provider Scorecard below and start assessing your security operations."
          downloadLink="/files/rhombus-physical-security-provider-scorecard.pdf"
          buttonText="Download Checklist"
          image={data.image}
          imageAlt="Physical Security Checklist"
        />
        <ExperienceCards />
        <TrialBanner />
      </GlobalLayout>
    );
  };
  const GET_IMAGES = graphql`
    query {
      image: file(
        relativePath: { eq: "components/landing-pages/img/scorecard.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
    }
  `;

  return <StaticQuery query={GET_IMAGES} render={renderContent} />;
}
